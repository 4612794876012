import useDebounce from "@app/hooks/useDebounce";
import { displayText } from "@app/lib/currency";
import { css } from "@linaria/core";
import {
  Typography,
  Input,
  Radio,
  Form,
  useForm,
  Button,
} from "@reifyhealth/picasso-pkg";
import { useState } from "react";
import { UseMutateFunction } from "react-query";

const invoiceTotalBlock = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--size-6);
  background: var(--component-background);
  border: 1px solid var(--component-border);

  .ant-typography {
    font-size: var(--font-size-h-5);
    font-weight: var(--font-weight-medium);
    line-height: normal;
  }
`;

const invoiceRowTitle = css`
  display: flex;
  padding-bottom: var(--size-5);

  .ant-typography {
    font-weight: var(--font-weight-small);
  }

  .ant-input-affix-wrapper {
    width: 250px;
  }
`;

const invoiceRowSubtotalValue = css`
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: var(--size-5);
`;

const invoiceRowModificationValue = css`
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-bottom: var(--size-5);
  gap: 8px;

  .ant-typography {
    font-size: var(--font-size-footnote);
    font-weight: var(--font-weight-small);
  }

  .ant-input-affix-wrapper {
    width: 60px;
  }
`;

const invoiceTotalModificationTypeNote = css`
  padding-top: 7px;
`;

const invoiceRowTotalTitle = css`
  display: flex;
  padding: var(--size-2);

  .ant-typography {
    font-size: var(--font-size-h-4);
  }
`;
const invoiceRowTotalValue = css`
  display: flex;
  flex-direction: row-reverse;

  .ant-typography {
    font-size: var(--font-size-h-4);
  }
`;

const invoiceColTitle = css`
  background: var(--component-background-subtle);
  flex: 1;
  padding: var(--size-6);
`;

const invoiceColValue = css`
  flex: 1;
  padding: var(--size-6);
`;

type Invoice = IFinancials2__InvoiceDetailsQuery["data"]["invoice"];

type InvoiceTotalModificationFormProps = {
  invoice: Pick<
    Invoice,
    "id" | "externalStatus" | "subtotal" | "total" | "totalModification"
  >;
  updateTotalModification: UseMutateFunction<
    IUpdateInvoiceTotalModificationMutation,
    unknown,
    Exact<{
      input: IFinancials2__UpdateInvoiceTotalModificationInput;
    }>,
    {
      previousData: IFinancials2__InvoiceDetailsQuery;
    }
  >;
  deleteTotalModification: UseMutateFunction<
    IDeleteInvoiceTotalModificationMutation,
    unknown,
    Exact<{
      input: IFinancials2__DeleteInvoiceTotalModificationInput;
    }>,
    {
      previousData: IFinancials2__InvoiceDetailsQuery;
    }
  >;
};

const InvoiceTotalModificationForm = ({
  invoice,
  updateTotalModification,
  deleteTotalModification,
}: InvoiceTotalModificationFormProps) => {
  const { externalStatus, subtotal, total, totalModification } = invoice;

  const [formInstance] = useForm<{
    totalModificationName: string;
    totalModificationType: "FIXED" | "PERCENTAGE";
    totalModificationValue: string;
  }>();

  const [formInitialValues, setFormInitialValues] = useState({
    totalModificationName: totalModification?.name,
    totalModificationType: totalModification?.modificationType || "FIXED",
    totalModificationValue:
      totalModification?.modificationType === "FIXED"
        ? totalModification.fixedValue?.amount
        : totalModification?.percentageValue,
  });

  const submitTotalModificationFormDebounced = useDebounce(
    formInstance.submit,
    1000
  );

  const clearTotalModification = () =>
    deleteTotalModification(
      {
        input: {
          invoiceId: invoice.id,
        },
      },
      {
        onSuccess(_data, _variables, _context) {
          setFormInitialValues({
            totalModificationName: undefined,
            totalModificationType: "FIXED",
            totalModificationValue: undefined,
          });
          formInstance.resetFields();
        },
      }
    );

  return (
    <Form
      form={formInstance}
      initialValues={formInitialValues}
      onFinish={(values) => {
        const baseInput = {
          invoiceId: invoice.id,
          name: values.totalModificationName,
          modificationType: values.totalModificationType,
        };
        if (values.totalModificationType === "FIXED") {
          updateTotalModification({
            input: {
              ...baseInput,
              fixedValue: {
                amount: values.totalModificationValue,
                currencyCode: "USD",
              },
            },
          });
        } else {
          updateTotalModification({
            input: {
              ...baseInput,
              percentageValue: values.totalModificationValue,
            },
          });
        }
      }}
    >
      <div className={invoiceTotalBlock}>
        <div className={invoiceColTitle}>
          <div className={invoiceRowTitle}>
            <Typography.Text>Sub-total:</Typography.Text>
          </div>
          <div className={invoiceRowTitle}>
            <Form.Item
              name="totalModificationName"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input
                disabled={externalStatus !== "DRAFT"}
                placeholder="Adjustment"
                allowClear={true}
                onChange={submitTotalModificationFormDebounced}
              />
            </Form.Item>
          </div>
          <div className={invoiceRowTotalTitle}>
            <Typography.Text>Invoice Total:</Typography.Text>
          </div>
        </div>
        <div className={invoiceColValue}>
          <div className={invoiceRowSubtotalValue}>
            <Typography.Text data-testid="invoice-subtotal">
              {subtotal && displayText(subtotal)}
            </Typography.Text>
          </div>
          <div className={invoiceRowModificationValue}>
            <Typography.Text
              className={invoiceTotalModificationTypeNote}
              type="secondary"
            >
              Enter positive or negative amounts to adjust the total
            </Typography.Text>
            <Form.Item
              name="totalModificationType"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group
                size="small"
                disabled={externalStatus !== "DRAFT"}
                buttonStyle="solid"
                onChange={(_) => {
                  formInstance.setFieldsValue({
                    totalModificationValue: undefined,
                  });
                  formInstance.validateFields(["totalModificationValue"]);
                }}
              >
                <Radio.Button value="FIXED">$</Radio.Button>
                <Radio.Button value="PERCENTAGE">%</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="totalModificationValue"
              rules={[{ required: true, message: "" }]}
            >
              <Input
                data-testid="invoice-input"
                disabled={externalStatus !== "DRAFT"}
                size="small"
                allowClear={true}
                onChange={submitTotalModificationFormDebounced}
              />
            </Form.Item>
            <Form.Item>
              <Button
                disabled={!invoice.totalModification}
                type="text"
                ghost
                onClick={clearTotalModification}
              >
                Clear
              </Button>
            </Form.Item>
          </div>
          <div className={invoiceRowTotalValue}>
            <Typography.Text data-testid="invoice-total">
              {total && displayText(total)}
            </Typography.Text>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default InvoiceTotalModificationForm;
